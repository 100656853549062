import { passwordResetLink } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const PasswordResetLink = (Data, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await passwordResetLink(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (status === 200) {
      onComplete();
    }
  } catch (error) {
    Error(error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
