import Cookies from "js-cookie";
import { login } from "api";
import { IsUser } from "Redux/User/action";
import { SetToken } from "Redux/Token/action";
import { Error } from "Responses/Response";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";

export const LoginUser = (Data, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await login(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
    if (status === 200) {
      setCookie("userDetail", JSON.stringify(data?.user));
      if (data?.user?.is_verified){
        if (process.env.NODE_ENV === 'development') {
          Cookies.set('token-local', JSON.stringify(data));
        }
  
        dispatch(IsUser(data?.user));
        dispatch(SetToken(data?.token));

      }


      onComplete(data?.user?.is_verified);
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      Error('Server error');
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
