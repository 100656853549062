/* eslint-disable import/no-anonymous-default-export */
import TagManager from "react-gtm-module";
import { ActionTypes } from "./types";

let INITIAL_STATE = {
  userDetail: null,
  userLoggedIn: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.USER_DETAIL:
      TagManager.dataLayer({
        dataLayer: {
         'event': 'identify',
         'user': action.payload
        }
      });

      return {
        ...state,
        userDetail: action.payload,
      };
    case ActionTypes.USER_LOGGED_IN:
      return {
        ...state,
        userLoggedIn: action.payload,
      };

    default:
      return state;
  }
};
