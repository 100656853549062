import { register } from "api";
import { IsUser } from "Redux/User/action";
import { Error } from "Responses/Response";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { SetToken } from "Redux/Token/action";

export const Register = (Data, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await register(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (status === 201) {
      setCookie("userDetail", JSON.stringify(data?.user))
      if (onComplete){
        onComplete()
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      Error('Server error');
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
