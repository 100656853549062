import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Styled from './styled';

import { ReactComponent as DefaultLogo } from "assets/svg/logo-arootah.svg";
import { ReactComponent as ArrowForwardIcon } from "assets/svg/arrow-forward.svg";

import { INDEX_PATH, SIGN_IN_PATH } from 'utils/routes';

import { Logout } from "Redux/Logout/action";
import { useToken } from 'utils/utility';

const Layout = ({ bg, overflow, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useToken();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false);

    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, [bg]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLogoutHandler = () => {
    dispatch(Logout(() => {
      navigate(SIGN_IN_PATH, { replace: true });
    }));
  }

  return (
    <Styled.Wrapper>
      <Styled.Background $bg={bg} $animate={animate} $animateDuration={500} />

      <Styled.Header>
        <Styled.HeaderLink to={INDEX_PATH}>
          <DefaultLogo />
        </Styled.HeaderLink>

        {token && <Styled.Logout onClick={onLogoutHandler}>
          Log out
          <ArrowForwardIcon />
        </Styled.Logout>}
      </Styled.Header>

      <Styled.Content $overflow={overflow}>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default Layout;
