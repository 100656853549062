export const getCookie = (key) => {
  return localStorage.getItem(key);
};
export const setCookie = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeCookie = (key) => {
  return localStorage.removeItem(key);
};

export const removeAllCookie = () => {
  localStorage.removeItem("workbook-token");
  localStorage.removeItem("userDetail");
  localStorage.removeItem("JourneyId");
  localStorage.removeItem("MasterPlanId");
  localStorage.removeItem("domainName");
  localStorage.removeItem("domainId");
  localStorage.removeItem("step");

  return;
};
