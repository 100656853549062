import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ minHeight, fullHeight }) => {
  return (
    <Container $minHeight={minHeight} $fullHeight={fullHeight}>
      <StyledSpinner animation="border" />
    </Container>
  );
};

export default Loader;

const Container = styled.div`
  // height: calc(100vh - 200px);
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $minHeight }) => $minHeight && css`
    min-height: ${$minHeight}px;
  `}

  ${({ $fullHeight }) => $fullHeight && css`
    height: 100%;
  `}
`;

const StyledSpinner = styled(Spinner)`
  width: 45px;
  height: 45px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
