import PropTypes, { InferProps } from "prop-types";
import { forwardRef, Ref } from "react";
import styled, { css } from "styled-components";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  $suffix?: boolean;
  $size?: "sm" | "md";
  $error?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input<InputProps>`
  padding: 8px 12px;
  width: 100%;
  color: var(--neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: 1px solid var(--neutral-200, #E5E5E5);
  border-radius: 4px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${({ $suffix }) =>
    $suffix &&
    css`
      padding-right: 38px;
    `}

  ${({ $size }) =>
    $size &&
    $size === "sm" &&
    css`
      padding: 7px 12px;
      font-size: 12px;
    `}

  ${({ $size }) =>
    $size &&
    $size === "md" &&
    css`
      padding: 12px;
    `}

  ::placeholder {
    color: var(--neutral-400, var(--Placeholder, #A3A3A3));
  }

  :focus,
  :active {
    border-color #71AACA;
    box-shadow: 0px 0px 0px 2px #D0E3ED;
  }

  ${({ $error }) => $error && `
    border-color: #FF4D4F;
  `};
`;

const SuffixWrap = styled.span<InputProps>`
  position: absolute;
  right: 12px;
  color: var(--neutral-400, var(--Placeholder, #a3a3a3));
  background-color: #fff;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;

  ${({ $size }) =>
    $size &&
    $size === "sm" &&
    css`
      top: 8px;
    `}
`;

type InputFieldProps = {
  suffix?: string | React.ComponentType<any>;
  size?: "sm" | "md";
  error?: boolean;
} & InferProps<typeof Input.propTypes>;

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ suffix: Suffix, size, error, ...props }, ref: Ref<HTMLInputElement>) => {
    return (
      <Wrapper>
        <Input
          {...props}
          data-testid="input-field"
          $suffix={!!Suffix}
          $size={size}
          $error={error}
          ref={ref}
        />
        {Suffix && (
          <SuffixWrap $size={size}>
            {typeof Suffix === "function" ? <Suffix /> : Suffix}
          </SuffixWrap>
        )}
      </Wrapper>
    );
  }
);

export default InputField;
