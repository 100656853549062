import { BaseService } from "./base";

export const checkAuth = () => BaseService.get("/user/check_auth/", null, false);
export const removeAuth = () => BaseService.get("user/remove_auth/");
export const register = (data) => BaseService.post("user/", data, false);
export const login = (data) => BaseService.post("user/login/", data, false);
export const logout = () => BaseService.get("user/logout/");
export const passwordResetLink = (data) => BaseService.post("user/send_reset_password_email/", data, false);
export const resetPassword = (data, token) => BaseService.post("user/reset_password/", data, true, token);
export const verifyOtp = (data) => BaseService.post("user/verify_otp/", data, false)
export const resendOtp = (data) => BaseService.post("user/resend_otp/", data, false)
