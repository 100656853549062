import React from 'react';

import VerificationMain from '../../../components/Auth/Verification';

// @ts-ignore
import Layout from '../../../components/Auth/Layout';
// @ts-ignore
import { getCookie } from "../../../utils/StorageVariables"

const Verification = () => {
  let data = JSON.parse(getCookie("userDetail"));
  return (
    <Layout overflow="initial" bg="login">
      {data?.is_verified ? <VerificationMain/> : <VerificationMain active={"true"} /> }
    </Layout>
  );
}

export default Verification;
