import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ForgotPasswordContent from '../../../components/Auth/ForgotPassword';
import Layout from '../../../components/Auth/Layout';
import LegalTerms from 'components/Auth/LegalTerms';
import { FORGOT_PASSWORD_PATH } from 'utils/routes';

import * as Styled from './styled';

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const callbackRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get('termsRequired') || '0';

  const onRequestLegalHandle = callback => {
    callbackRef.current = callback;
    navigate(`${FORGOT_PASSWORD_PATH}?termsRequired=1`);
  }

  const onLegalTermsHandle = () => {
    navigate(`${FORGOT_PASSWORD_PATH}?termsRequired=0`, { replace: true, state: { hasAcceptedTerms: true }});

    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = null;
    }
  }

  return (
    <Layout bg={step === '2' ? 'terms' : 'login'} overflow="hidden">
      <>
        <Styled.PasswordWrapper $animate={step === '0'}>
          <ForgotPasswordContent active={true} onRequestLegal={onRequestLegalHandle} />
        </Styled.PasswordWrapper>

        <Styled.LegalWrapper $animate={step === '1'}>
          <LegalTerms active={step === '1'} onNext={onLegalTermsHandle} />
        </Styled.LegalWrapper>
      </>
    </Layout>
  );
}

export default ForgotPassword;
