import styled, { css, keyframes } from 'styled-components';

const passwordAnimate = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const animate = keyframes`
  0% {
    transform: translateY(15%) scale(0.99);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
`;

export const PasswordWrapper = styled.div`
  opacity: 0;
  visibility: hidden;

  ${({ $animate }) => $animate && css`
    animation: ${passwordAnimate} 0.6s cubic-bezier(0, 0, 0, 1) forwards;
  `}

  /* display: flex;
  margin: 0 auto;
  max-width: 1100px;
  max-height: 75vh;
  height: 100vh;
  width: 100%;

  ${({ theme }) => theme.max("lg")`
    max-height: calc(100vh - 50px);
  `} */
`;

export const LegalWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(50%);
  opacity: 0;
  visibility: hidden;

  ${({ $animate }) => $animate && css`
    animation: ${animate} 0.6s cubic-bezier(0, 0, 0, 1) forwards;
  `}

  /* display: flex;
  margin: 0 auto;
  max-width: 1100px;
  max-height: 75vh;
  height: 100vh;
  width: 100%;

  ${({ theme }) => theme.max("lg")`
    max-height: calc(100vh - 50px);
  `} */
`;
