import { verifyOtp } from "api";
import { IsUser } from "Redux/User/action";
import { SetToken } from "Redux/Token/action";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { getSimplifiedError } from "../../utils/error";
import { INDEX_PATH } from '../../utils/routes'

export const VerifyOTPAction = (Data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.OTP_LOADING, payload: Data });

    const { data, status } = await verifyOtp(Data);

    dispatch({
      type: ActionTypes.OTP_SUCCESS,
      payload: data
    });

    if (status === 201) {
      dispatch(IsUser(data?.user));
      dispatch(SetToken(data?.token));
      if (navigate){
        navigate(INDEX_PATH, { replace: true });
      }
      
    }
  } catch (error) {
    const getError = getSimplifiedError(error)
    if (error.response && error.response.status === 403) {
      Error('Server error');
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.OTP_FAIL,
      payload: getError.length > 0 ? getError[0] : error
    });
  }
};
