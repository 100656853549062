import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, /* useLocation, */ useNavigate } from 'react-router-dom';

import { Formik } from "formik";
import * as yup from "yup";

import * as Layout from '../styled';
import * as Styled from './styled';

import { ErrorFocus } from '../../../utils/ErrorFocus';

import { SIGN_IN_PATH } from 'utils/routes';
import { PasswordResetLink } from 'Redux/PasswordResetLink/action';

const schema = yup.object().shape({
  email: yup.string().required('Email is required')
});

const initialValues = {
  email: ''
}

const url = new URL(window.location.href);
const redirectUrl = url.protocol + '//' + url.host;

const ForgotPassword = (/* { onRequestLegal } */) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  const $form = useRef(null);

  const [animate, setAnimate] = useState(false);

  const { loading } = useSelector(
    (state) => state.resetPasswordLinkReducer
  );

  // const hasAcceptedTerms = location.state?.hasAcceptedTerms;

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNavigate = () => {
    navigate(`/forgot-password/success`, { state: { type: 'forgot', email: $form.current.values.email } });
  }

  const onSubmitHandle = form => {
    const data = { ...form, url: redirectUrl };
    dispatch(PasswordResetLink(data, onNavigate));

    /* if (!hasAcceptedTerms) {
      onRequestLegal(() => {
        dispatch(PasswordResetLink(data, onNavigate));
      });
    } else {
      dispatch(PasswordResetLink(data, onNavigate));
    } */
  }

  return (
    <Layout.Screen>
      <Layout.Column $middle>
        <Styled.FormWrapper>
          <Layout.Title $animate={animate} $animateDelay={100}>Reset your password</Layout.Title>
          <Styled.FormText $animate={animate} $animateDelay={150}>First, let’s find your account.</Styled.FormText>

          <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandle}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            innerRef={$form}
          >
            {({ handleSubmit, handleReset, handleChange, values, errors }) => (
              <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                <Styled.FormGroup $animate={animate} $animateDelay={200}>
                  <Styled.Label className="authLabel">Email*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={errors.email}
                      aria-label="Email"
                    />
                    <Styled.FormError>
                      {errors.email}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.SubmitWrapper $animate={animate} $animateDelay={250}>
                  <Styled.SubmitStyled
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Continue
                  </Styled.SubmitStyled>
                </Styled.SubmitWrapper>

                <ErrorFocus />
              </Styled.FormStyled>
            )}
          </Formik>

          <Styled.LinkboxStyled $animate={animate} $animateDelay={300}>
            <Link to={`${SIGN_IN_PATH}`}>Cancel</Link>
          </Styled.LinkboxStyled>
        </Styled.FormWrapper>
      </Layout.Column>
    </Layout.Screen>
  );
}

export default ForgotPassword;
