/* eslint-disable import/no-anonymous-default-export */
import { ActionTypes } from "./types";

let INITIAL_STATE = {
  token: '',
  loading: false,
  success: false,
  error: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: action.payload?.silent ? false : true
      };

    case ActionTypes.SUCCESS: {
      const token = action.payload.token;

      return {
        ...state,
        token,
        loading: false,
        success: true
      };
    }

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: (action.payload.token)
      };

    case ActionTypes.REMOVE_TOKEN:
      return {
        ...state,
        token: ''
      };

    default:
      return state;
  }
};
