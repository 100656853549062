import { resetPassword } from "api";
import { Error, Success } from "Responses/Response";
import { ActionTypes } from "./types";

export const ResetPassword = (Data, Token, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await resetPassword(Data, Token);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (status === 200) {
      onComplete();
      Success("Password changed successfully");
    }
  } catch (error) {
    Error(error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
