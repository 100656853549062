/* eslint-disable no-unused-vars */
import "./App.css";
import "./styles.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Notification from "Notification";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./appRoutes";
import { Provider } from "react-redux";
import store from "Redux/store";

import TagManager from "react-gtm-module";

if (process.env.NODE_ENV !== "development") {
  const tagManagerArgs = {
    gtmId: "GTM-5KCPG3C",
  };

  TagManager.initialize(tagManagerArgs);
}

function App() {
  return (
    <>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="207150144695-s8f0j0lsk6oun0hgvh7dndebjgvu831n.apps.googleusercontent.com">
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <AppRoutes />
              </DndProvider>
            </ThemeProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
