import styled, { css, keyframes } from 'styled-components';
import SlickSlider from "react-slick";

const laptopAnimate = keyframes`
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  height: 100%;
  transform: translateX(40px);
  opacity: 0;

  ${({ $animate }) => $animate && css`
    animation: ${laptopAnimate} 0.6s cubic-bezier(0, 0, 0, 1) forwards;
  `}
`;

export const Slider = styled(SlickSlider)`
  height: 100%;

  .slick-list,
  .slick-track,
  .slick-slide {
   height: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: initial;

    ${({ theme }) => theme.max('sm')`
      padding: 6px;
    `}
  }

  .slick-dots {
    position: absolute;
    bottom: -10px;

    li {
      width: 14px;
      height: 14px;
      margin: 0;

      button {
        width: 14px;
        height: 14px;
        padding: 0;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: #CDCDCD;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #525252;
          }
        }
      }
    }
  }
`;

export const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Img = styled.img`
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
`;
