import React from 'react';

import LandingMain from '../../../components/Auth/Landing';
import Layout from '../../../components/Auth/Layout';

const Landing = () => {
  return (
    <Layout bg="dashboard">
      <LandingMain />
    </Layout>
  );
}

export default Landing;
