import { removeAuth } from "api";
import { IsUser } from "Redux/User/action";
import { Error } from "Responses/Response";
import { removeAllCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { RemoveToken } from "Redux/Token/action";

export const Logout = (onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data: response } = await removeAuth();

    dispatch({
      type: ActionTypes.SUCCESS
    });

    if (response) {
      dispatch(RemoveToken());
      removeAllCookie();
      dispatch(IsUser(null));
      onComplete();
    }

  } catch (error) {
    Error(error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });

    removeAllCookie();
    dispatch(IsUser(null));
    onComplete();
  }
};
