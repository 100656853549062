import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import Button from '../../Button';
import { Form, Submit } from '../styled';
import TextBox from '../../../atoms/TextBox';
import TextBoxLabel from '../../../atoms/TextBoxLabel';
import { fadeInUpMixin } from '../animated';

const laptopAnimate = keyframes`
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FormWrapper = styled(Form)`
  
`;

export const FormText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  margin-bottom: 22px;
  text-align: center;
  ${fadeInUpMixin}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 624px;

  img {
    transform: translateX(40px);
    opacity: 0;
  }

  ${({ $app }) => $app && $app === 'pp' && css`
    img {
      margin-left: 24%;
      width: 50vh;
    }
  `}

  ${({ $app }) => $app && $app === 'dm' && css`
    img {
      margin-left: 16%;
      width: 62vh;
    }
  `}

  ${({ $app }) => $app && $app === 'gps' && css`
    img {
      margin-left: 0%;
      width: 96vh;
    }
  `}

  ${({ $animate }) => $animate && css`
    img {
      animation: ${laptopAnimate} 0.6s cubic-bezier(0, 0, 0, 1) forwards;
    }
  `}
`;

export const Heading = styled.span`
  font-weight: 700;
  font-size: 50px;
  line-height: 160%;
  margin-bottom: 12px;

  ${({ theme }) => theme.max('lg')`
    font-size: 29px;
    line-height: 130%;
    margin-bottom: 15px;
  `}
`;

export const Subheading = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 34px;
  max-width: 465px;

  ${({ theme }) => theme.max('lg')`
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 18px;
  `}
`;

export const Features = styled.ul`
  list-style-type: none;

  ${({ theme }) => theme.max('lg')`
    padding-left: 20px;
  `}
`;

export const Feature = styled.li`
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin: 6px 0;
  color: #000;
  position: relative;

  ${({ theme }) => theme.max('lg')`
    margin: 8px 0;
  `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #000;
    margin-top: 5px;
    margin-left: -12px;
  }
`;

export const Linkbox = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 20px;
  color: #1B2A3D;
  ${fadeInUpMixin}

  a {
    margin-left: 7px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #1271A6;
  }
`;

export const FormStyled = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  width: 100%;
  max-width: 388px;
  margin: 22px auto 0;
  ${fadeInUpMixin}
`;

export const Label = styled(TextBoxLabel)`
  padding: 20px 0 6px;

  &:first-child {
    padding-top: 0;
  }
`;

export const TextBoxs = styled(TextBox)`
  width: 100%;

  &[type="password"] {
    width: calc(100% - 36px);
    border-right: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
  }
`;

export const Eye = styled(Button)`
  position: absolute;
  background: transparent;
  opacity: 0.8;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #e3e5e9;
  border-top: 1px solid #e3e5e9;
  border-bottom: 1px solid #e3e5e9;
  border-radius: 0 0.25rem 0.25rem 0;
`;

export const FormInfo = styled.div`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #818EA3;
`;

export const FormError = styled.div`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #f00;
`;

export const SubmitWrapper = styled.div`
  ${fadeInUpMixin}
`;

export const SubmitStyled = styled(Submit)`
  margin-top: 12px;
`;
