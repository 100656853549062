import axios from "axios";
// import MockAdapter from 'axios-mock-adapter';

import { ROOT_API_URL } from "Config/config";
import { removeAllCookie } from "utils/StorageVariables";
import { Error } from "Responses/Response";
import { getToken } from "utils/utility";

// const mock = new MockAdapter(axios);

/* // Mock any GET request to any URL
mock.onGet().reply(function(config) {
  // If request is to '/api/path', respond with a 403 error and a custom error message
  if (config.url === '/user/check_auth/') {
      return [403, { message: 'Forbidden' }];
  }
  // Else for other urls, do not mock and perform actual request
  return [200, {}]
}); */

const onError = async (error) => {
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx

    if (error.response.status === 401) {
      try {
        removeAllCookie();
      } catch (error) {
        console.log('🔴', error);
        Error(error);
      }

      return Promise.reject(error);
    } else if (error.response.status === 403) {
      console.log('🔴', error);

      if (!sessionStorage.getItem('isReloaded')) {
        sessionStorage.setItem('isReloaded', 'true');
        window.location.reload();
      } else {
        setTimeout(() => {
          sessionStorage.removeItem('isReloaded');
        }, 10000);
      }
    }
  }

  return Promise.reject(error);
};

const request = async (options, isSecure) => {
  let baseURL = ROOT_API_URL;
  const token = getToken();

  let headers = {};

  if (isSecure && (token || options.token)) {
    headers["Authorization"] = `Token ${(token || options.token)} `;
  }

  const client = axios.create({
    baseURL,
    headers: { ...headers },
  });

  return client(options).catch(onError);
};

export class BaseService {
  static get = (url, isSecure = true) => {
    return request(
      {
        url,
        method: "GET",
      },
      isSecure
    );
  };

  static post = (url, data, isSecure = true, token) => {
    return request(
      {
        url,
        method: "POST",
        data,
        token
      },
      isSecure
    );
  };

  static put = (url, data, isSecure = true) => {
    return request(
      {
        url,
        method: "PUT",
        data,
      },
      isSecure
    );
  };
  static patch = (url, data, isSecure = true) => {
    return request(
      {
        url,
        method: "PATCH",
        data,
      },
      isSecure
    );
  };

  static remove = (url, isSecure = true) => {
    return request(
      {
        url,
        method: "DELETE",
      },
      isSecure
    );
  };
}
