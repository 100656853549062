import Cookies from "js-cookie";
import { resendOtp } from "api";
import { IsUser } from "Redux/User/action";
import { Error } from "Responses/Response";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { getAppBaseURL } from "../../utils/utility";

export const ResendOTPAction = (Data, onComplete) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.OTP_LOADING, payload: Data });

    const url = getAppBaseURL();
    const dataForm = {
      ...Data,
      redirect_url : `https://${url}/`
    }

    const { data, status } = await resendOtp(dataForm);

    dispatch({
      type: ActionTypes.OTP_SUCCESS,
      payload: data
    });

    if (status === 200) {
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
    } else {
      Error(error);
    }

    dispatch({
      type: ActionTypes.OTP_FAIL,
      payload: error
    });
  }
};
