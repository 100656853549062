import React, { useState } from 'react';

import LegalContent from '../../LegalModal/Content';
import { BackNavigate } from '../Components/BackNavigate';

import * as Layout from '../styled';
import * as Styled from './styled';

import { INDEX_PATH } from 'utils/routes';

const LegalTerms = ({ active, onNext }) => {
  const [loading, setLoading] = useState(false);

  const onSubmitHandle = () => {
    setLoading(true);

    // No rush
    setTimeout(() => {
      onNext();
      setLoading(false);
    }, 350);
  }

  if (!active)
    return null;

  return (
    <Layout.Screen $terms>
      <BackNavigate to={INDEX_PATH} alternative />

      <Styled.LegalWrapper>
        <LegalContent loading={loading} onSubmit={onSubmitHandle} />
      </Styled.LegalWrapper>
    </Layout.Screen>
  );
}

export default LegalTerms;
