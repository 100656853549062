import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { zoomInSlightlyMixin } from '../animated';

export const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 50px;
  pointer-events: none;
  background-size: cover;
  background-position: center;

  ${({ theme }) => theme.max('lg')`
    margin-top: 50px;
  `}

  ${({ $bg }) => $bg === 'login' && css`
    background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1690301295/arootah/fo2rmkcnctze45asorgh.svg');
  `}

  ${({ $bg }) => $bg === 'register' && css`
    background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1690301193/arootah/th5fipirozhkx2hzgqqq.svg');

    ${({ theme }) => theme.max('md')`
      background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1690301242/arootah/rwpu4zrnthkklgj06epb.svg');
    `}

    ${({ theme }) => theme.max('sm')`
      background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1690301270/arootah/ztxpxpjxp7vbojip61fy.svg');
    `}
  `}

  ${({ $bg }) => $bg === 'dashboard' && css`
    background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1713193899/arootah/rueqoivakrb6hdwhkou7.svg');
  `}

  ${({ $bg }) => $bg === 'terms' && css`
    background-image: url('https://res.cloudinary.com/dwvhtukvx/image/upload/v1690301334/arootah/bpgua7xntetj57bovdoz.svg');
  `}

  ${zoomInSlightlyMixin}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #05192E;
  padding: 10px 32px;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.max('lg')`
    height: 50px;
    padding: 10px 0 10px 16px;
    border-bottom: 2px solid #71AACA;

    svg {
      width: 100%;
    }
  `}
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logout = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  gap: 8px;
  color: #73B1BB;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;

  svg {
    transition: transform 0.3s;
  }

  :hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

export const Content = styled.div`
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: relative;

  ${({ $overflow }) => $overflow === 'hidden' && css`
    overflow-y: hidden;
  `}
`;
