import { combineReducers } from "redux";

import tokenReducer from './Token/reducer';
import registerUserReducer from "./Register/reducer";
import loginUserReducer from "./Login/reducer";
import logoutUserReducer from "./Logout/reducer";

import resetPasswordReducer from "./ResetPasword/reducer";
import resetPasswordLinkReducer from "./PasswordResetLink/reducer";
import verifyOtpReducer from "./VerifyOtp/reducer"
import resendOtpReducer from "./ResendOtp/reducer"
import userDetailReducer from "./User/reducer";

export default combineReducers({
  tokenReducer,
  registerUserReducer,
  loginUserReducer,
  logoutUserReducer,

  resetPasswordReducer,
  resetPasswordLinkReducer,

  userDetailReducer,
  verifyOtpReducer,
  resendOtpReducer
});